<script>
    import { storeToRefs } from "pinia";  
    import { useloggedUserInfo,moduleStore} from "./stores/UserInfo";
export default {
  data(){
    return{
       showAddWizard:false,
       showAddBookMark:false,
       showNofication:false,
       wizardType:'',
       controllerName:null,
       actionName:null,
       isShowMenuHeader:true
       
    }
  },
    setup() {
        const store = useloggedUserInfo();
        const modulestore = moduleStore();
        const { GetUserInfo } = storeToRefs(store);
        const { GetModuleId } = storeToRefs(modulestore);
        return { GetUserInfo, GetModuleId };
    },
}
</script>
<template>
  <section>
    <ModuleHeader v-if="(GetUserInfo != null && isShowMenuHeader && $route.path.toLowerCase() !='/company/initialsetup')" :modulemasterid="String(GetModuleId)"></ModuleHeader>   
    <section id="main-content" class="main-content" v-if="GetUserInfo != null">
      <div class="container-fluid">
          <!-- <div class="row" style="min-height:100vh;">
              <div class="col-12 mt-0 p-0"> -->
                <router-view/>
              <!-- </div>
          </div> -->
      </div>
      <tooltip :width="250"/>
    </section>
  </section> 
</template>
<style>
/* @import "@/assets/base.css"; */

@media (min-width: 1024px) {
  body {
    display: flex;
    place-items: center;
  }
}
a{text-decoration:none;}
.color-picker-container .current-color {
  height: 30px;
  width: 30px;
}
.color-box .form-control.color-box-inside {
    left: -2px;
    top: -1px;
    width: 35px;
}

</style>
